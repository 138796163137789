import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";

export const links = [
  { id: 1, link: "#", title: "Home" },
  { id: 2, link: "#about", title: "About" },
  { id: 3, link: "#expertise", title: "My Expertise" },
  { id: 4, link: "#portfolio", title: "Portfolio" },
  { id: 5, link: "#contact", title: "Contact" },
];

export const socials = [
  //{ id: 1, link: "https://instagram.com", icon: <AiOutlineInstagram /> },
  //{ id: 2, link: "https://twitter.com", icon: <AiOutlineTwitter /> },
  {
    id: 1,
    link: "https://linkedin.com/in/klára-hoffmannová-223145232",
    icon: <AiFillLinkedin />,
  },
  { id: 2, link: "https://github.com/KlaHoff", icon: <AiFillGithub /> },
];
