import "./about.css";
import AboutImage from "../../assets/about.JPEG";
import CV from "../../assets/cv.pdf";
import { HiDownload } from "react-icons/hi";
import Card from "../../components/Card";
import data from "./data";

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
      <div className="container about__container">
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImage} alt="About Image" />
          </div>
        </div>
        <div className="about__right">
          <h2>About Me</h2>
          <div className="about__cards">
            {data.map((item) => (
              <Card key={item.id} className="about__card">
                <span className="about__card-icon">{item.icon}</span>
                <h5>{item.title}</h5>
                <small>{item.desc}</small>
              </Card>
            ))}
          </div>
          <p>
            I'm a technology enthusiast with a focus on the MERN stack, where I
            build dynamic and responsive web applications. Alongside MERN, I've
            worked with a range of other technologies (see the next section for
            more info), which has broadened my skills and adaptability in the
            tech world. I hold a degree in Software Engineering from Southampton
            Solent University and am currently pursuing a part-time MSc in
            Computer Science at the University of York.
          </p>
          <p>
            What I enjoy most about being a full stack developer is the
            opportunity to combine technical precision with creative design. My
            background in graphic design, CGI, and animation allows me to
            approach projects with an eye for both functionality and aesthetics.
            I'm always striving to create applications that are not just
            effective but also visually engaging and user-friendly. For me, it's
            about paying attention to the details that make a difference in the
            UX and constantly learning and growing as I take on new challenges
            in the field.
          </p>
          <a href={CV} download className="btn primary">
            Download CV
            <HiDownload />
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
