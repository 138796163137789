import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";

const data = [
  {
    id: 1,
    link: "https://linkedin.com/in/klára-hoffmannová-223145232",
    icon: <AiFillLinkedin />,
  },
  { id: 4, link: "https://github.com/KlaHoff", icon: <AiFillGithub /> },
];

export default data;
