import { AiOutlineDocker } from "react-icons/ai";
import { RiReactjsLine } from "react-icons/ri";
import { FaServer } from "react-icons/fa";
import { AiFillAndroid } from "react-icons/ai";

const data = [
  {
    id: 1,
    icon: <AiOutlineDocker />,
    title: "Microservices & Cloud",
    desc: "I have experience building scalable microservices using Java, Spring Boot, Docker, and Azure. My work involves RESTful APIs, CI/CD, and cloud-based architectures.",
  },
  {
    id: 2,
    icon: <RiReactjsLine />,
    title: "Frontend Development",
    desc: "I create responsive, modern web applications with JavaScript and React, ensuring they perform well and are accessible on all devices.",
  },
  {
    id: 3,
    icon: <FaServer />,
    title: "Backend Development",
    desc: "With a background in Java, Python, Node.js, and MongoDB, I develop secure and efficient backend systems, focusing on reliability and scalability.",
  },
  {
    id: 4,
    icon: <AiFillAndroid />,
    title: "Mobile App Development",
    desc: "I develop cross-platform mobile apps using Kotlin and AR features, combining 3D graphics with real-world data for interactive user experiences.",
  },
];

export default data;
