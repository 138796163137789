import { HiOutlineMail } from "react-icons/hi";
import { AiFillLinkedin } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";

const data = [
  { id: 1, icon: <HiOutlineMail />, link: "mailto:klahoffmannova@gmail.com" },
  { id: 2, icon: <FaWhatsapp />, link: "https://wa.me/+447310063114" },
  {
    id: 3,
    icon: <AiFillLinkedin />,
    link: "https://linkedin.com/in/klára-hoffmannová-223145232",
  },
];

// alternative whatsApp link
// https://wa.me/yournumber
// https://api.whatsapp.com/send/?phone=%2B233557097546

export default data;
