import "./expertise.css";
import data from "./data";
import Card from "../../components/Card";

const Expertise = () => {
  return (
    <section id="expertise">
      <h2>My Expertise</h2>
      <p>
        Here's how my experience translates into effective solutions across
        these key areas
      </p>
      <div className="container expertise__container" data-aos="fade-up">
        {data.map((item) => (
          <Card key={item.id} className="expertise light">
            <div className="expertise__icon">{item.icon}</div>
            <div className="expertise__details">
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default Expertise;
