import Image1 from "../../assets/project1.jpg";
import Image2 from "../../assets/project2.jpg";
import Image3 from "../../assets/project3.jpg";
import Image4 from "../../assets/project4.jpg";

const data = [
  {
    id: 1,
    category: "mobile app",
    image: Image1,
    title: "VR App for Android",
    desc: "This AR app overlays the phone's camera feed with computer-generated data, allowing users to navigate an unfamiliar city or point their phone at a point of interest to see more information about it.",
    tech: "Kotlin, OpenGL",
    demo: "",
    github: "https://github.com/KlaHoff/COM626",
  },
  {
    id: 2,
    category: "frontend",
    image: Image2,
    title: "Crime App",
    desc: "Web-based interactive application designed to visualise crime data on a map using data from the UK Police API documentation.",
    tech: "JavaScript, React, React-Leaflet",
    demo: "",
    github: "",
  },
  {
    id: 3,
    category: "backend",
    image: Image3,
    title: "LoraWan IoT Effectiveness",
    desc: "Evaluation project to explore the capabilities and potential applications of LoRaWAN technology. ATS, the project sponsor, identified a real-world need and requested research and testing to assess the system's effectiveness and efficiency, determining if its implementation is advisable.",
    tech: "NodeRED, LoRaWAN, JavaScript",
    demo: "https://docs.google.com/document/d/1Lp5cYpbZw2sSJ4cw6IFrL3nL8_5WCK0O/edit?usp=drive_link&ouid=101696162620554379271&rtpof=true&sd=true",
    github: "",
  },
  {
    id: 4,
    category: "backend",
    image: Image4,
    title: "Azure & SpringBoot Project",
    desc: "A scalable microservice developed using Java technology (Springboot), Docker containers, and GitHub actions, following appropriate DevOps practices with a focus on cloud-provided services (Azure), programming to APIs (ReST, Swagger), service-oriented architectures, event-driven systems, continuous integration, quality control, and modern tooling.",
    tech: "Docker, Spring, ReST, MongoDB, OpenAPI, Nginx",
    demo: "",
    github: "https://github.com/ArconOffl/COM619-Assignment1",
  },
];

export default data;
