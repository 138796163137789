import { FaAward } from "react-icons/fa";
import { TbBooks } from "react-icons/tb";
import { BiHappyHeartEyes } from "react-icons/bi";

const data = [
  {
    id: 1,
    icon: <FaAward />,
    title: "Education",
    desc: "BSc Software Engineering",
  },
  { id: 2, icon: <TbBooks />, title: "Projects", desc: "22+ Completed" },
  {
    id: 3,
    icon: <BiHappyHeartEyes />,
    title: "Further studies",
    desc: "Part-time MSc Computer Science student",
  },
];

export default data;
